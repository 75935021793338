import { useState, useContext } from 'react';
import { PrivilegesContext } from '../context/privilegesContext';

const useFilterHandlers = () => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [dateFrom, setDateFrom] = useState<any>();
  const [dateTo, setDateTo] = useState<any>();
  const { menuId, setMenuId } = useContext(PrivilegesContext);
  const [filters, setFilters] = useState<any>();

  const handleMenuId = (selectedLocation: any) => {
    setMenuId(selectedLocation);
  };

  const handleDate = (selectedDate: any) => {
    setSelectedDate(selectedDate);
  };

  const handleFromDate = (selectedDate: any) => {
    setDateFrom(selectedDate);
  };

  const handleToDate = (selectedDate: any) => {
    setDateTo(selectedDate);
  };

  const handleFilters = (filters: any) => {
    setFilters(filters);
  };

  return {
    menuId,
    selectedDate,
    dateFrom,
    dateTo,
    filters,
    handleMenuId,
    handleDate,
    handleFromDate,
    handleToDate,
    handleFilters
  };
};

export default useFilterHandlers;
