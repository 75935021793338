import { useContext, useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import "react-datepicker/dist/react-datepicker.css";
import useDateFormatter from "../../hooks/useDateFormatter";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import { ReceiptReportFilterProps } from "./ReceiptReportFilters.type";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import { PrivilegesContext } from "../../context/privilegesContext";

const ReceiptReportFilters: React.FC<ReceiptReportFilterProps> = ({
  sendMenuIdToParent,
  sendDateToParent
}) => {
  const { t } = useTranslation();
  const UserMenus = localStorage.getItem("UserMenus");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const token = localStorage.getItem("token");
  const { formatDateSP } = useDateFormatter();
  const [fromDate, setFromDate] = useState<any>();
  const {menuId, setMenuId } = useContext(PrivilegesContext);

  
      
      const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
      
      const options = UserMenusParsed.map((location: any) => {
        return { value: location.IdMenu, label: location.MenuName };
      });

  useEffect(() => {
    if (location.pathname === "/reports/receiptReport" && menuId) {
      const getData = async () => {
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const requestBody = {
          IdMenu: menuId,
          "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
          "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
        };

        try {
          const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: requestBody,
          });
          const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
          setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [menuId, location.pathname, currentMonth]);

  // useEffect(() => {
  //   if (allowedDatesFromPicker.length > 0) {
  //     setFromDate(formatDateSP(allowedDatesFromPicker[0]));
  //   }
  // }, [allowedDatesFromPicker]);

  const handleSelectChange = (selectedOption: any) => {
    setMenuId(selectedOption);
  };

  useEffect(() => {
    if (fromDate) {
      sendDateToParent(fromDate);
    }
  }, [fromDate]);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);


  const { disabledDate } = useDateDisabling({ allowedDatesFromPicker });

  const isMobile = useIsMobileDevice();

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });


  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-28 flex flex-col md:flex-row items-center justify-center md:mb-8">
    
    <div className="w-full">
            <Heading
              align="left"
              children={t("receiptReport.title")}
              classes="pt-16 md:pt-0 min-w-full md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("receiptReport.subTitle")}
              classes="font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>
    
      <div className="w-full md:hidden">
        <Select
          classes="flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4"
          options={options}
          onChange={handleSelectChange}
          defaultValue={options[0]}
        />
        </div>

          <div className="flex flex-col h-fit w-full md:w-8/12 mt-4">
            <p className="text-sm md:text-base mb-1">{t("receiptReport.date")}</p>
            <fieldset>
              <CustomDatePicker
              dateValue={fromDate}
              onDateChange={setFromDate}
              disabledDate={disabledDate}
              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
              placeholder={t("datePicker.placeholder")}
              allowedDates={allowedDatesFromPicker}
            />
             
            </fieldset>
          </div>
    </Wrapper>
  );
};

export default ReceiptReportFilters;
