import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import useIsMobileDevice from '../../../hooks/useIsMobileDevice';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'; // Import the plugin
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/en-gb'; // Import the English GB locale (week starts on Monday)
import locale from 'antd/es/date-picker/locale/en_GB'; 

interface CustomDatePickerProps {
  dateValue?: string | null;
  onDateChange: (date: string) => void;
  // onTimeChange: (time: string) => void;
  disabledDate?: (current: any) => boolean;
  handleMonthChange?: (date: Date) => void;
  placeholder: string;
  className?: string;
  isToDatePicker?: boolean; // to differentiate between fromDate and toDate
  allowedDates?: any[];
  isPeriodReport?: boolean;
  mode?: "date" | "month" | "year" | "week" | "quarter" | any;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  dateValue,
  onDateChange,
  // onTimeChange,
  disabledDate,
  handleMonthChange,
  placeholder,
  className,
  isToDatePicker = false,
  allowedDates,
  isPeriodReport,
  mode
}) => {
  // const [mode, setMode] = useState<'date' | 'month' | 'year' | 'week' | 'quarter'>('date');
  const [currentMode, setCurrentMode] = useState<any>(mode);
  const [isOpen, setIsOpen] = useState(false); // State to control picker open/close
  dayjs.extend(quarterOfYear); // Extend dayjs with the quarterOfYear plugin
  dayjs.extend(weekOfYear);
dayjs.extend(localeData);

dayjs.locale('en-gb'); // This ensures week starts on Monday globally

  
  // const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  
  // const [range, setRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
  const ref = useRef(null);
  
  // Parse the default date from props and set default time (12 AM or 11 AM)
  // dayjs(fromDate).hour(0).minute(0)
  // const defaultDate = dateValue ? dayjs(`${dateValue}`, 'DD/MM/YYYY') : null;
  const [defOpt, setDefOpt] = useState<any>(dayjs(dateValue, 'DD/MM/YYYY'))
  useEffect(() => {
    if(currentMode !== mode) {
      setDefOpt(dayjs(dateValue, 'DD/MM/YYYY'))
    }
  }, [dateValue])
  
  const isAllowedDate = (date: dayjs.Dayjs) => {
    return allowedDates?.some((allowedDate) =>
      dayjs(allowedDate).isSame(date, 'day')
    );
  };
  const isSelectedDate = (current: dayjs.Dayjs) => {
    return dateValue && dayjs(dateValue).isSame(current, 'day');
  };

  const isMobile = useIsMobileDevice();

  useEffect(() => {
    // Remove the ant-picker-focused class when the picker is closed
    if (!isOpen && ref.current) {
      const pickerElement = (ref.current as any).pickerRef;
      pickerElement?.classList.remove('ant-picker-focused');
    }
  }, [isOpen]);



  return (
    <div className="flex flex-col h-14">
    <fieldset>
    <DatePicker
    locale={locale}
      suffixIcon={isOpen ? <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.5L5 1.5L9 5.5" stroke="#0DB5D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> :  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1.5L5 5.5L9 1.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

      }
      ref={ref}
      format='DD/MM/YYYY'
      datatype="dd/mm/yyyy"
      picker={mode}
      open={isOpen}
      // defaultValue={defaultDate}
      value={defOpt}
      onChange={(date, dateString) => {
          if (typeof dateString === 'string') {
            const [datePart] = dateString.split(' '); // Split the date and time
            let selectedDate = date;
            if(mode === "week" && date) {
              selectedDate = date.startOf('week');
              const startOfWeekString = selectedDate.format('DD/MM/YYYY');
              onDateChange(startOfWeekString);
              setDefOpt(selectedDate);
            } else {
              onDateChange(datePart)
              setDefOpt(date)
            }
          } else {
            console.error('dateString is not a string:', dateString);
          }
        }} // Pass the function that updates state
      inputReadOnly
      onClick={() => setIsOpen(!isOpen)} 
      showNow={false}
      onPanelChange={(date: any, mode: string) => {
        setCurrentMode(mode);
        if (handleMonthChange) {
          handleMonthChange(date.toDate());
        }
      }}
      onOpenChange={(open) => {
        setIsOpen(open) // Sync open state with internal antd logic
        if(!open){
          setCurrentMode(mode)
        }
        
      }}
      disabled={mode !== "date" && isToDatePicker ? true : false}
      placeholder={placeholder}
      rootClassName={`${isOpen ? "bg-white text-custom-textBlackColor outline-0" : ""}`} // Add class when date is selected
      popupClassName={`${isToDatePicker  && isMobile && !isPeriodReport ? "left-2.5" : ""}`}
      className={className || ` bg-custom-bgBlue py-2 border-none w-full text-xxs md:text-xs h-8 md:h-10 ${isOpen ? "text-black" : "text-white"}` }
      cellRender={(current: any) => {
        const isSelected = (unit: string) => {
          return dateValue && dayjs(dateValue).isSame(current);
        };
        if (mode === 'date') {
          const isAllowed = isAllowedDate(current);
          const isSelected = isSelectedDate(current);
          return (
            <div
              className={`ant-picker-cell-inner ${
                isAllowed ? 'text-[#7DD181]' : ''
              }
               ${isSelected ? 'bg-custom-bgBlue text-white rounded-full' : ' '}`}
            >
              {current.date()}
            </div>
          );
        } else if (mode === 'week') {
          const isAllowed = isAllowedDate(current);
          const isSelected = dateValue && dayjs(dateValue).isSame(current, 'week');
          return (
            <div
              className={`ant-picker-cell-inner ${
                isAllowed ? 'text-[#7DD181]' : ''
              } ${isSelected ? 'bg-custom-bgBlue text-yourCustomTextColor rounded-full' : ''}`}
            >
              {current.date()}
            </div>
          );
        }
         else if (mode === 'month') {
          // Render month names instead of days
          return (
            <div
              className={`ant-picker-cell-inner ${
                isSelected('month') ? 'bg-custom-bgBlue text-white rounded-full' : ''
              }`}
            >
              {dayjs(current).format('MMM')}
            </div>
          );        
        } else if (mode === 'quarter') {
          const currentQuarter = dayjs(current).quarter();
          const selectedQuarter = dateValue ? dayjs(dateValue).quarter() : null;

          const quarterToMonthNames: any = {
            1: 'Jan',
            2: 'Apr',
            3: 'Jul',
            4: 'Oct',
          };
          return (
            <div
            className={`ant-picker-cell-inner ${
              currentQuarter === selectedQuarter
                ? ''
                : ''
            }`}
          >
            {quarterToMonthNames[currentQuarter]}
          </div>
          );
        } 
        else if (mode === 'year' || mode === 'decade') {
          // Render year numbers instead of days
          return (
            <div
              className={`ant-picker-cell-inner ${
                isSelected('year') ? 'bg-custom-bgBlue text-white rounded-full' : ''
              }`}
            >
              {dayjs(current).format('YYYY')}
            </div>
          );
        }
        return <div>{current.date()}</div>; // Default rendering
      }}
      
    />
    </fieldset>
  </div>
  );
};
export default CustomDatePicker;
