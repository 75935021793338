import React, { useContext, useEffect, useRef, useState } from "react";
import { TopNavbarProps } from "./TopNavbar.type";
import { Link, useNavigate } from "react-router-dom";
import Select from "../select/Select";
import { PrivilegesContext } from "../../../context/privilegesContext";
import i18n from "i18next";
import { Language } from "../../navbarComponent/Navbar.type";
import { useTranslation } from "react-i18next";


const TopNavbar: React.FC<TopNavbarProps> = ({
  filterOptions,
  onFilterChange,
  activeFilter,
}) => {

  const [labelWidths, setLabelWidths] = useState<number[]>([]);
  const labelRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const { i18n } = useTranslation();


  useEffect(() => {
    const widths = labelRefs.current.map((ref) => ref?.offsetWidth || 0);
    setLabelWidths(widths);
  }, [filterOptions]);

  const UserMenus = localStorage.getItem("UserMenus");

  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;


  const options = UserMenusParsed && UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });

  const projectsOptions = [
    {value: "https://www.google.co.uk/", label: "CMS"},
    // {value: "https://www.google.com", label: "POS"},
  ];

  const languageOptions = [
    {value: "en", label: "English"},
    {value: "nl", label: "Dutch"},
  ]


  const { setMenuId } = useContext(PrivilegesContext);
  const UserInfo = localStorage.getItem("priv");

  const UserInfoParsed = UserInfo ? JSON.parse(UserInfo) : null;

  const savedLanguage = localStorage.getItem("selectedLanguage") || languageOptions[0].value;

  // Set the default language on component mount
  useEffect(() => {
    i18n.changeLanguage(savedLanguage);
  }, [savedLanguage, i18n]);

  const handleSelectChange = (selectedOption: any) => {
    
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }
    window.location.reload();

  };


  const handleLanguageChange = (lng: any) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng); // Save the selected language in local storage
  };
  const handleProjectChange = (selectedOption: any) => {
      window.open(selectedOption, '_blank');
  }

  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;

  return (
    <nav
      className="w-4/5 fixed z-[1000000] bg-white hidden md:flex justify-center border-b py-2 h-20"
      // style={{ width: "calc(100% - 12.3rem)" }}
    >
      <div className="w-[95%] flex justify-between">

    
      <div className="w-1/4 flex items-center">
        <Select
          classes="hidden md:flex justify-center rounded-lg border border-custom-bgBlue bg-white relative shadow-custom1 py-2 "
          options={options}
          onChange={handleSelectChange}
          defaultValue={option && option}
          />

      </div>
      <div className="flex">


      <div className=" w-24 mr-4 flex items-center">
        <Select
          classes="hidden md:flex justify-center rounded-lg border border-gray-200 bg-custom-bgBlue relative shadow-custom1 py-2 "
          options={projectsOptions}
          onChange={handleProjectChange}
          defaultValue={projectsOptions && projectsOptions[0]}
          color="bg-custom-bgBlue"
          textColor="text-white"
          iconColor="#FFF"
          />

      </div>

      <div className=" w-28 flex items-center">
        <Select
          classes="hidden md:flex justify-center rounded-lg border border-custom-bgBlue bg-custom-bgBlue relative shadow-custom1 py-2 "
          options={languageOptions}
          onChange={handleLanguageChange}
          defaultValue={languageOptions.find(option => option.value === savedLanguage)}
          // color="bg-custom-bgBlue"
          textColor="text-custom-bgBlue"
          iconColor="#0DB5D6"
          />

      </div>

      <div className="ml-4 flex items-center">
        <img src="/istockphoto-1223671392-612x612.jpg" alt="" className="w-8 h-8 rounded-full mr-2"/>
        <span>
          <p>{UserInfoParsed[0] ? UserInfoParsed[0].Username : ""}</p>
          <p className="text-gray-400">{UserInfoParsed[0] && UserInfoParsed[0].NameRole}</p>
        </span>
      </div>
          </div>
          </div>
    </nav>
  );
};

export default TopNavbar;
  