import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { PeriodReportTableProps } from "./PeriodReportTable.type";
import { flexRender, useReactTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";

const PeriodReportTable: React.FC<PeriodReportTableProps> = ({
  options,
  tableHeads,
  vatTotals,
  discountsPercentageReport,
}) => {
  const { t } = useTranslation();

  const transformVatTotals = (vatTotals: any) => {
    const groupedByVatName = vatTotals.reduce((acc: any, item: any) => {
      if (!acc[item.VatName]) {
        acc[item.VatName] = {};
      }
      acc[item.VatName][item.TaxType] = item.Ammount;
      return acc;
    }, {});

    const transformedData = Object.keys(groupedByVatName).map((vatName) => ({
      VatName: vatName,
      TaxType2Amount: groupedByVatName[vatName][2] || 0,
      TaxType3Amount: groupedByVatName[vatName][3] || 0,
    }));

    const totals = transformedData.reduce(
      (acc, item) => {
        acc.TaxType2Total += item.TaxType2Amount;
        acc.TaxType3Total += item.TaxType3Amount;
        return acc;
      },
      { TaxType2Total: 0, TaxType3Total: 0 }
    );

    return { transformedData, totals };
  };

  const { transformedData, totals } =
    vatTotals && vatTotals.length > 0
      ? transformVatTotals(vatTotals)
      : { transformedData: [], totals: { TaxType2Total: 0, TaxType3Total: 0 } };

  let discountTotal;
  {
    discountsPercentageReport
      ? (discountTotal = discountsPercentageReport.reduce(
          (sum: any, item: any) => sum + item.DiscountAmount,
          0
        ))
      : (discountTotal = 0);
  }

  const [isMobile, setIsMobile] = useState<boolean>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;

    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  const table = useReactTable<any>(options);

  return (
    <Wrapper onClick={() => {}} classes="w-full ">
      <div className="rounded-[10px] shadow-custom4 mt-1">
        <table className=" w-full text-left rounded-[10px] overflow-hidden">
          <thead className="border-b border-custom-bgLightPurple rounded-[10px] bg-custom-tableHeadBg">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="min-w-1/2"
                style={{
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  overflow: "hidden",
                }}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="text-base font-medium py-2 pl-4 border-b border-custom-bgLightPurple md:border-b-0"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="">
            {table.getRowModel().rows.map((row, index) => {
              const data: any = row.original;
              const isTotal = data.VatName === "Total";

              return (
                <tr
                  key={row.id}
                  className={`${index % 2 !== 0 ? "" : ""} ${isTotal ? "bg-custom-tableBgPurple" : ""}`}
                  style={{
                    backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",
                  }}
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id} className="py-4 pl-4">
                      {/* Check if the cell value is a number and format it */}
                      {typeof cell.getValue() === 'number' 
                        ? cell.getValue().toFixed(2) // Format to 2 decimal places
                        : flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            }
              
            )}
          </tbody>
        </table>
      </div>
      <div className={`text-[8px] md:text-xs flex justify-between items-center w-[97%] mx-auto mt-2`}>
  <span className="flex items-center gap-2">
    {t("periodReport.showing")}
    <select
      className="border rounded-lg px-2 py-2"
      value={table.getState().pagination.pageSize}
      onChange={e => {
        table.setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p style={{ color: "#5A5A5A" }}>
      {t("periodReport.itemsPerPage")}
    </p>
  </span>

  <span>
    {(() => {
      const totalPages = table.getPageCount();
      const currentPage = table.getState().pagination.pageIndex;
      const maxButtons = isMobile ? 2 : 5;
      const halfMaxButtons = Math.floor(maxButtons / 2);

      let startPage = Math.max(0, currentPage - halfMaxButtons);
      let endPage = Math.min(totalPages - 1, currentPage + halfMaxButtons);

      if (endPage - startPage + 1 < maxButtons) {
        if (startPage === 0) {
          endPage = Math.min(maxButtons - 1, totalPages - 1);
        } else if (endPage >= totalPages - 2) {
          startPage = Math.max(0, totalPages - maxButtons);
        }
      }

      return (
        <>
          {startPage > 0 && (
            <button
              className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
              onClick={() => table.setPageIndex(0)}
            >
              1
            </button>
          )}
          {startPage > 1 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={startPage + i}
              className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${currentPage === startPage + i ? 'border border-custom-bgPurple text-custom-bgPurple rounded-lg' : ''}`}
              onClick={() => table.setPageIndex(startPage + i)}
            >
              {startPage + i + 1}
            </button>
          ))}
          {endPage < totalPages - 3 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {endPage < totalPages - 2 && (
            <>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 2)}
              >
                {totalPages - 1}
              </button>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 1)}
              >
                {totalPages}
              </button>
            </>
          )}
        </>
      );
    })()}
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.nextPage()}
      disabled={!table.getCanNextPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.next")}
    </button>
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.lastPage()}
      disabled={!table.getCanPreviousPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.end")}
    </button>
  </span>
</div>
    </Wrapper>
  );
};

export default PeriodReportTable;
