import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import NavbarComponent from "./components/navbarComponent/NavbarComponent";
import "./i18n";
import Wrapper from "./components/common/wrapper/Wrapper";
import { PrivilegesProvider } from "./context/privilegesContext";
import { AppRoutes } from "./types/Enums";
import LogIn from "./pages/logIn/LogIn";
import Reports from "./pages/reports/Reports";
import { LoadingLiveDataContext } from "./context/isLoadingLiveDataContext";
import Loader from "./components/common/loader/Loader";
import { useContext, useEffect, useState } from "react";
import Settings from "./pages/settings/Settings";
import TopNavbar from "./components/common/topNavbar/TopNavbar";
import { ThemeProvider } from "./context/themeContext";
import { useFilterOptions } from "./utilities/constants";
import ProtectedRoute from "./components/common/protectedRoute/ProtectedRoute"; // Import ProtectedRoute
import useIsMobileDevice from "./hooks/useIsMobileDevice";

const queryClient = new QueryClient();

const LoginElement = () => {
  const isLogIn = localStorage.getItem("isLogIn");

  return isLogIn === null ? <LogIn /> : <Navigate to="/" />;
};

const App = () => {
  const location = useLocation();
  const filterOptions = useFilterOptions();
  const { isLoadingLiveData } = useContext(LoadingLiveDataContext);
  const [isLogIn, setIsLogIn] = useState<any>(null);
  useEffect(() => {
    const storedValue = localStorage.getItem("isLogIn");
    setIsLogIn(storedValue === "true"); // Convert string to boolean
  }, [location]);

  const isMobile = useIsMobileDevice();

  const [selectedFilter, setSelectedFilter] = useState(location.pathname);

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
      <PrivilegesProvider>
        {isLoadingLiveData && <Loader />}
        <Wrapper
          classes="flex justify-between overflow-hidden"
          onClick={() => {}}
        >
          <div className="md:w-1/5" >
            {isLogIn === true ? <NavbarComponent /> : <></>}
          </div>
          <div
            className={` ${isLogIn && isMobile ? "w-full" : isLogIn ? "w-4/5" : ""}`}
          >
            {isLogIn && <TopNavbar
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        activeFilter={selectedFilter}
        />}
   
            <Routes>
              <Route path={AppRoutes.Home} element={<ProtectedRoute><div className="w-11/12 mx-auto pt-14">You are on home page</div></ProtectedRoute>} />
              <Route path={AppRoutes.Login} element={<LoginElement />} />
              <Route path={AppRoutes.Reports} element={<ProtectedRoute><Reports/></ProtectedRoute> }>
                <Route
                  path={AppRoutes.ReportsDashboard}
                  element={<Reports />}
                />
                <Route
                  path={AppRoutes.ReportsSales}
                  element={<Reports />}
                />
                <Route
                  path={AppRoutes.ReportsZreport}
                  element={<Reports />}
                />
                <Route
                  path={AppRoutes.ReportsPeriodReport} 
                  element={<Reports />}
                />
                <Route 
                path={AppRoutes.SoldProducts}
                element={<Reports />}
                />
                <Route 
                path={AppRoutes.PercentageReport}
                element={<Reports />}
                />
                <Route 
                path={AppRoutes.MainCategory}
                element={<Reports />}
                />
                <Route 
                path={AppRoutes.ReceiptReport} 
                element={<Reports />}/>
              </Route>
              <Route path={AppRoutes.Settings} element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="*" element={<div>Not found</div>} />
            </Routes>
          </div>
        </Wrapper>
      </PrivilegesProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
