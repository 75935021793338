import { useTranslation } from "react-i18next";
import CalendarIcon from "../icons/CalendarIcon";
import LogoIcon from "../icons/LogoIcon";
import ProductsIcon from "../icons/ProductsIcon";
import RegisterIcon from "../icons/RegisterIcon";
import ReportsIcon from "../icons/ReportsIcon";
import SettingsIcon from "../icons/SettingsIcon";
import { Language } from "./Navbar.type";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenuIcon from "../icons/BurgerMenuIcon";
import LogOutIcon from "../icons/LogOutIcon";
import BlackLogoIcon from "../icons/BlackLogoIcon";
import CloseIcon from "../icons/CloseIcon";
import Select from "../common/select/Select";
import Text from "../common/text/Text";
import ProfileIcon from "../icons/ProfileIcon";
import LanguageIcon from "../icons/LanguageIcon";
import QrCodeSupportIcon from "../icons/QrCodeSupportIcon";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import { PrivilegesContext } from "../../context/privilegesContext";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";
import "./NavbarComponent.css"
import SupportIcon from "../icons/SupportIcon";
import BlackLogoWithoutTextIcon from "../icons/BlackLogoWithouTextIcon";
import HomeIcon from "../icons/HomeIcon";
import WhatsAppIcon from "../icons/WhatsAppIcon";


const NavbarComponent: React.FC = () => {
  const lngs: { [key: string]: Language } = {
    nl: { nativeName: "Nl" },
    en: { nativeName: "En" },
  };
  const { setMenuId } = useContext(PrivilegesContext);
  const location = useLocation();

  const [activeLink, setActiveLink] = useState<string>(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setActiveLink(window.location.pathname);
    };
    setActiveLink(window.location.pathname)

    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, [window.location.pathname]);

  const [, , removeCookie] = useCookies(["token", "isLogIn", "selectedMenuId"]);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isReportsDropdownOpen, setIsReportsDropdownOpen] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean>();
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    // Initialize state from local storage if it exists
    const savedState = localStorage.getItem("tt");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [clickedOptionInSidebar, setClickedOptionInSidebar] = useState<any>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /iphone|ipad|ipod|android/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;
  
    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  const toggleReportsDropdown = () => {
    setIsReportsDropdownOpen(!isReportsDropdownOpen);
  };

  const handleOpenNavbar = () => {
    setIsAnimating(true);
    setIsOpen(true);
  };

  const handleCloseNavbar = () => {
    setIsAnimating(true);
    setIsOpen(false);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem("token");
    localStorage.removeItem("isLogIn");
    localStorage.removeItem("selectedMenuId");
    localStorage.removeItem("UserMenus");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expires");
    localStorage.removeItem("exportOptions")
    localStorage.removeItem("latitude");
    localStorage.removeItem("longitude")
    localStorage.removeItem("fullAddress")
    localStorage.removeItem("MI")
    localStorage.removeItem("menuId")
    // localStorage.removeItem("priv")
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "expires_date=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  
  // const UserMenus = localStorage.getItem("UserMenus");

  // const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;

  // const options = UserMenusParsed && UserMenusParsed.map((location: any) => {
  //   return { value: location.IdMenu, label: location.MenuName };
  // });
  
  // const handleSelectChange = (selectedOption: any) => {
  //   setMenuId(selectedOption)
  // };

  const [activeSection, setActiveSection] = useState("");

const handleReportsClick = () => {
  setActiveSection("reports");
  if(!location.pathname.includes("/reports")){
    navigate("/reports/sales")
  }
};

// const handleHomeClick = () => {
//   setActiveSection("home");
// };

useEffect(() => {
  if(location.pathname === "/reports/sales" || location.pathname === "/reports/zreport" || location.pathname === "/reports/periodReport" || location.pathname === "/reports/soldProducts" || location.pathname === "/reports/percentageReport" || location.pathname === "/reports/mainCategory" || location.pathname === "/reports/receiptReport"){
    setActiveSection("reports")
  }

},[location.pathname])

  return (
    <nav className={`bg-white md:bg-white border-gray-200 w-full fixed h-14 md:w-1/5 z-50 flex right-0 md:left-0 items-center border-b border-r md:border-b-0 md:h-screen md:items-start`} >
      <div className="min-w-full">
        {!isMobile ? (<></>) : (
        <span className="w-11/12 mx-auto flex justify-between"><BlackLogoIcon />      <button
            data-collapse-toggle="navbar-default"
            type="button"
            className=" md:hidden "
            aria-controls="navbar-default"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={isOpen ? handleCloseNavbar : handleOpenNavbar}
          >
            <BurgerMenuIcon />
       
          </button></span>)}

        <div className="flex flex-col justify-between h-full ">
          <span className={`w-full hidden md:flex border-b h-20 items-center`} onClick={() => {}}>
            {activeSection === "reports" ? 
            <span className="flex w-full h-full items-center">
              <span className="w-1/3 px-1.5 border-r h-full flex justify-center items-center">
              <BlackLogoWithoutTextIcon />
              </span>
              <span className="w-11/12">
              <p className="text-xl pl-6">{t("navBarLinks.reports")}</p>
              </span>
              </span> 
            :
             activeSection !== "reports" ?
            <span className="pl-4"><BlackLogoIcon /> </span> 
            :
            <span><BlackLogoIcon /></span>}
          
          </span>
          <span className={`w-full pl-4`}>
          </span>
          {/* Icons on the top */}
          <div className="w-full flex">

          <div className={`${activeSection === "reports" ? "w-1/3 px-2 justify-center" : "w-full"} ${activeSection === "reports" ? "border-r" : "w-full"} overflow-hidden flex-col items-center justify-center mb-4 space-y-2 hidden md:flex md:justify-between`}  style={{ height: "calc(100vh - 5rem)" }}>
            <div className={`w-full flex flex-col  ${activeSection === "reports" ? "items-center" : ""}`}>
            <Text limit={activeSection === "reports" ? 4 : 10} align="text-center" children={t("navBarLinks.main")} classes={`${activeSection === "reports" ? "" : "ml-4"}  text-center text-custom-textBlackColor hidden md:flex text-xl mt-4 mb-2 ${activeSection !== "reports" ? "" : ""}`} color="" size=""/>
            <a
            href="/home"
            className={`my-1 p-2 w-11/12 mx-auto flex items-center ${activeSection === "reports" ? "justify-center" : ""} py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/home" ? "bg-custom-lightBlue shadow-custom1" : ""}`}
            onClick={() => {}}
            >
            <HomeIcon />
            
            <p className={`text-base pl-2 ${activeSection === "reports" ? "hidden" : " "}`}>
           {t("navBarLinks.home")}
           </p>
          </a>
          <a
            className={`my-1 p-2 w-11/12 mx-auto flex items-center ${activeSection === "reports" ? "justify-center" : ""} ${activeLink === "/reports/sales" || activeLink === "/reports/zreport" || activeLink === "/reports/periodReport" || activeLink === "/reports/soldProducts" || activeLink === "/reports/percentageReport" || activeLink === "/reports/mainCategory" || activeLink === "/reports/receiptReport" || activeSection === "reports" ? "bg-custom-lightBlue shadow-custom1" : ""} py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1`}
            onClick={handleReportsClick}
            >
            <span className="flex">

            <ReportsIcon />
            <p className={`text-base pl-2 ${activeSection === "reports" ? "hidden" : ""}`}>{t("navBarLinks.reports")}</p>
            </span>
          </a>
          <a
            href="/profile"
            className={`my-1 p-2 w-11/12 mx-auto flex items-center ${activeSection === "reports" ? "justify-center" : ""} py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/profile" ? "bg-custom-lightBlue shadow-custom1" : ""}`}
            onClick={() => {}}
            >
           <ProfileIcon />
           <p className={`text-base pl-2 ${activeSection === "reports" ? "hidden" : ""}`}>
           {t("navBarLinks.profile")}
           </p>
          </a>
          <a
            href="/settings"
            className={`my-1 p-2 w-11/12 mx-auto flex items-center ${activeSection === "reports" ? "justify-center" : ""} py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/settings" ? "bg-custom-lightBlue shadow-custom1" : ""}`}
            onClick={() => {}}
            >
           <SettingsIcon />
           <p className={`text-base pl-2 ${activeSection === "reports" ? "hidden" : ""}`}>
           {t("navBarLinks.settings")}
           </p>
          </a>
            </div>
            <div className="w-full">

            <div className={`bg-white absolute bottom-0 left-0 pb-4 min-w-full flex flex-col pt-2 border-t`}>
            <span className=" text-center flex justify-center items-center">{`${t("navBarLinks.needHelp")} ${t("navBarLinks.scanToChat")}` } <span className="ml-2"><WhatsAppIcon /></span></span>
<span className={` mx-auto`}>

      <QrCodeSupportIcon />
      
</span>
      <div className="min-w-full ">


        <a onClick={handleLogout} className={` mt-4 p-2 w-11/12 mx-auto flex items-center py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "" ? "bg-custom-lightBlue" : ""} ${activeSection === "reports" ? "" : ""}`}>
              <LogOutIcon />
              <p className={`text-base pl-2`}>
              {t("navBarLinks.logOut")}
            </p>
              </a> 
      </div>


            </div>
            </div>
        </div>
        {activeSection === "reports" && !isMobile && (
    <div className=" mt-2 w-11/12 mx-auto flex flex-col max-h-24 space-y-2 p-2 ">
      <a href="/reports/sales" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/sales" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.sales")}
      </a>
      <a href="/reports/zreport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/zreport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.zreport")}
      </a>
      <a href="/reports/periodReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/periodReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.periodReport")}
      </a>
      <a href="/reports/soldProducts" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/soldProducts" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.soldProducts")}
      </a>
      <a href="/reports/percentageReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/percentageReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.percentageReport")}
      </a>
      <a href="/reports/mainCategory" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/mainCategory" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.mainCategory")}
      </a>
      {/* <a href="/reports/receiptReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/receiptReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.receiptReport")}
      </a> */}
    </div>
  )}
              </div>
          {(isOpen || isAnimating) && (
            <div
              className={`z-50 w-full md:block md:w-auto absolute right-0 top-0 ${isOpen ? "animate-slideIn" : "animate-slideOut"}`}
              id="navbar-default"
              onAnimationEnd={handleAnimationEnd}
            >
              <ul className="flex flex-col items-start bg-white absolute right-0 top-0 h-screen w-8/12 rounded-bl-xl text-xl">
                <div className="w-full mx-auto flex justify-between items-center border-b-2 h-14 px-4">
                  <span onClick={handleCloseNavbar}><CloseIcon /></span>
                </div>
                <span className="pb-4 h-full w-11/12 mx-auto flex flex-col justify-between">
                    <div className="">
                  <p className="pl-2.5 pt-2">{t("navBarLinks.main")}</p>
                  <li className="mt-4 text-base w-full"><a href="/" className={`w-full flex items-center gap-2.5 p-2 rounded-lg ${activeLink === "/" ? "bg-custom-lightBlue shadow-custom1" : ""}`}><LogoIcon />{t("navBarLinks.home")}</a></li>
                  <li className="">   
                  <div className="relative">
  <a
    className={`p-2 w-full flex items-center justify-between py-2.5 rounded-lg`}
    onClick={toggleReportsDropdown}
  >
    <span className="flex items-center">
    <ReportsIcon />
    <p className="text-base pl-2">{t("navBarLinks.reports")}</p>
    </span>
    {isReportsDropdownOpen ? <ArrowUpIcon color="#0DB5D6"/> : <ArrowDownIcon color="#0DB5D6" />}
  </a>
  {isReportsDropdownOpen  && (
    <div className=" mt-2 w-11/12 mx-auto flex flex-col  space-y-2 p-2 report">
      <a href="/reports/sales" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/sales" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.sales")}
      </a>
      <a href="/reports/zreport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/zreport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.zreport")}
      </a>
      <a href="/reports/periodReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/periodReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.periodReport")}
      </a>
      <a href="/reports/soldProducts" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/soldProducts" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.soldProducts")}
      </a>
      <a href="/reports/percentageReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/percentageReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.percentageReport")}
      </a>
      <a href="/reports/mainCategory" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/mainCategory" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.mainCategory")}
      </a>
      {/* <a href="/reports/receiptReport" className={`rounded-lg block px-4 py-2 text-sm text-gray-700 hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/reports/receiptReport" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
        {t("reportsLinks.receiptReport")}
      </a> */}
      {/* Add more links as needed */}
    </div>
  )}
</div></li>
<li className="w-full text-base"><a href="/profile" className={`p-2 w-full flex items-center py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1 ${activeLink === "/profile" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
                    <ProfileIcon />
                    <span className="ml-2">
                    {t("navBarLinks.profile")}
                    </span>
                    </a></li>
                  <li className=" w-full mx-auto text-base"><a href="/settings" className={`p-2 w-full flex items-center py-2.5 rounded-lg hover:bg-custom-lightBlue hover:shadow-custom1  ${activeLink === "/settings" ? "bg-custom-lightBlue shadow-custom1" : ""}`}>
                    <SettingsIcon />
                    <span className="ml-2">
                    {t("navBarLinks.settings")}
                    </span>
                    </a></li>

                    </div>
                    <div className="">

                    <li className={`text-xs w-11/12 ${isReportsDropdownOpen ? " " : ""}`}>
                    <div className={`flex flex-col items-center justify-center`}>
                        <a href="https://api.whatsapp.com/message/UF3DYMABTETGH1?autoload=1&app_absent=0" target="_blank" className={`p-2 w-full flex items-center py-2.5 rounded-lg hover:bg-custom-lightBlue ${activeLink === "" ? "bg-custom-lightBlue" : ""}`}>
                        <SupportIcon /> 
                        <p className="text-base pl-2">
                          {t("navBarLinks.support")}
                        </p>
                        </a>

                      <a onClick={handleLogout} className={`p-2 pl-1.5 w-full flex items-center py-2.5 rounded-lg hover:bg-custom-lightBlue ${activeLink === "" ? "bg-custom-lightBlue" : ""}`}>
                        <LogOutIcon />
                        <p className="text-base pl-2">
                          {t("navBarLinks.logOut")}
                        </p>
                      </a>
                    </div>
                  </li>
                    </div>

                </span>
              </ul>
            </div>
          )}
          <div className={`z-40 h-screen top-0 bottom-0 left-0 right-0 bg-custom-transparentGray ${isOpen ? "absolute" : "hidden"}`} onClick={handleCloseNavbar}></div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
