import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { MenuType, ReceiptProps } from "./ReceiptReport.type";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import Dropdown from "../common/dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";

const ReceiptReports: React.FC<ReceiptProps> = ({menuId, date}) => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {t} = useTranslation();
  const [exportType, setExportType] = useState<any>("0");
  const [menuName, setMenuName] = useState("");
  const [fileUrl, setFileUrl] = useState<null | string>(null);

  const getBEData = async () =>  {
    if (location.pathname === "/reports/receiptReport" && menuId && date) {
          const requestBody = {
            IdMenu: menuId,
            TargetDate: date,
            Language: i18n.language,
            ExportType: exportType
        }
          setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getReceiptReport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });


            // console.log(apiResponse)

          } catch (error) {
            // console.error("Error fetching data:", error);
          } finally {
            setIsLoadingLiveData(false);
          }
    }
  };

  // useEffect(() => {
  //   if(menuId){
  //     const userMenusString = localStorage.getItem("UserMenus");
  //     if (userMenusString) {
  //       const userMenus: MenuType[] = JSON.parse(userMenusString);
  //       const matchedMenu = userMenus.find(menu => menu.IdMenu.toString() === menuId);
  //       if (matchedMenu) {
  //         setMenuName(matchedMenu.MenuName);
  //       }
  //     }
  //   }
  // }, [menuId]);

// useEffect(() => {
//   const userMenusString = localStorage.getItem("UserMenus");
//   if (userMenusString) {
//     const userMenus: MenuType[] = JSON.parse(userMenusString);
//     if (userMenus.length > 0) {
//       setMenuName(userMenus[0].MenuName);
//     }
//   }
// }, []);

const isMobile = useIsMobileDevice();


const handleExportChange = (exportType: any) => {
  setExportType(exportType[0])
}

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-4 pb-16 min-h-screen">
        <ExportFilterActions
          fileUrl={fileUrl}
          exportType={exportType}
          handleExportChange={handleExportChange}
          fetchReportData={getBEData}
        />
        

<ScrollToTopButton />

    </Wrapper>
  );
};

export default ReceiptReports;
