import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DropdownProps } from "./Dropdown.type";
import { v4 as uuidv4 } from 'uuid';
import ArrowUpIcon from "../../icons/ArrowUpIcon";
import ArrowDownIcon from "../../icons/ArrowDownIcon";

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  classes,
  defaultValue,
  title,
  isMultiSelect = true,
  isTaxDropdown = false,
  iconColor
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    defaultValue ? [defaultValue[0].value] : []
  );

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (isOpen && selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isOpen]);

  const handleSelect = (value: any) => {
    let newSelectedValues = [];

    if (isMultiSelect) {
      if (isTaxDropdown) {
        if (value == -1) {
          newSelectedValues = [-1];
        } else {
          if (selectedValues.includes(value)) {
            newSelectedValues = selectedValues.filter(item => item !== value);
          } else {
            newSelectedValues = selectedValues.filter((item: any) => item !== -1).concat(value);
          }
        }
      } else {
        if (value == "0") {
          newSelectedValues = [0];
        } else {
          if (selectedValues.includes(value)) {
            newSelectedValues = selectedValues.filter(item => item !== value);
          } else {
            newSelectedValues = selectedValues.filter((item: any) => item !== 0).concat(value);
          }
        }
      }
    } else {
      newSelectedValues = [value];
      setIsOpen(false); // Close the dropdown when an option is selected in single-select mode
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  // const selectClasses = classNames(
  //   "w-full text-start py-2.5 text-black bg-transparent border-0 dark:bg-white focus:outline-none focus:ring-0 focus:border-gray-200",
  //   classes
  // );

  const renderSelectedValues = () => {
    if (selectedValues.length === 0 || selectedValues.includes("0")) {
      return title;
    }
    return selectedValues.map((value) => options.find(option => option.value === value)?.label).join(', ');
  };

  return (
    <div className={`relative text-start w-full text-sm ${classes} `} ref={selectRef}>
      <button
        type="button"
        className={`w-full text-start py-1.5 md:py-2 px-4 bg-transparent border rounded-lg focus:outline-none flex items-center justify-between ${classes}`} style={{border: "1px solid #CED1D9"}}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isMultiSelect ? renderSelectedValues() : title}
       
        <span className="float-right">
        {isOpen ? <ArrowUpIcon color={iconColor && iconColor}/> : <ArrowDownIcon color={iconColor && iconColor}/>}
        </span>
      </button>
      {isOpen && (
        <ul className={` absolute z-10 mt-2 w-full max-h-40 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg ${classes}`}>
          {options.map((option) => (
            <li
              key={uuidv4()}
              className={classNames("py-2 px-4 hover:bg-gray-200 cursor-pointer border-b flex items-center", {
                "bg-gray-200": selectedValues.includes(option.value)
              })}
              onClick={() => handleSelect(option.value)}
            >
              {isMultiSelect && (
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.value)}
                  onChange={() => handleSelect(option.value)}
                  className="mr-1 accent-custom-bgBlue "
                />
              )}
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <div className="flex flex-wrap">
        {isMultiSelect && selectedValues.map((value: any) => {
          const option = options.find(option => option.value === value);
          return (
            <div key={uuidv4()} className="pl-2 flex items-center mb-1">
              <input
                type="checkbox"
                checked={true}
                onChange={() => handleSelect(value)}
                className="mr-1 accent-custom-bgBlue"
              />
              <span>{option?.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
