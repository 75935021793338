import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import SalesReportCard from "../salesReportsCard/SalesReportCard";
import AvarageFoodSpendingChart from "../avarageFoodSpendingChart/AvarageFoodSpendingChart";
import FeedbackAnalysis from "../feedbackAnalysis/FeedbackAnalysis";
import PaymentTypeChart from "../paymentTypeChart/PaymentTypeChart";
import { SaleProps } from "./SalesReports.type";
import Weather from "../weather/Weather";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";


const SalesReports: React.FC<SaleProps> = ({
  totalTurnoverValue,
  pendingValue,
  forecastValue,
  discountValue,
  occupiedValue,
  freeValue,
  avgBillValue,
  avgPPValue,
  avgFoodSpendingData,
  starsData,
  paymentData,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pb-16">
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 pb-8 pt-4">
        <SalesReportCard
          title={t("sales.totalTurnover")}
          value={totalTurnoverValue}
        />
        <SalesReportCard title={t("sales.pending")} value={pendingValue} />
        <SalesReportCard title={t("sales.forecast")} value={forecastValue} />
        <SalesReportCard title={t("sales.discounts")} value={discountValue} />
        <SalesReportCard title={t("sales.occupied")} value={occupiedValue} />
        <SalesReportCard title={t("sales.free")} value={freeValue} />
        <SalesReportCard title={t("sales.avgBill")} value={avgBillValue} />
        <SalesReportCard title={t("sales.avgPP")} value={avgPPValue} />
      </div>
<div className="grid grid-cols-1 md:grid-cols-2 gap-4">

      <div className="mb-1">
        <AvarageFoodSpendingChart backendData={avgFoodSpendingData} />
      </div>
      <div className="mb-1">
        <PaymentTypeChart backendData={paymentData} />
      </div>
      <div className="mb-1">
        <FeedbackAnalysis data={starsData} />
      </div>
      <div className="mb-1">
      <Weather />
      </div>
</div>
<ScrollToTopButton />

    </Wrapper>
  );
};

export default SalesReports;
