import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../dropdown/Dropdown";

interface ExportFilterActionsProps {
  fileUrl: string | null;
  exportType: string;
  handleExportChange: (exportType: any) => void;
  fetchReportData: () => void;
}

const ExportFilterActions: React.FC<ExportFilterActionsProps> = ({
  fileUrl,
  exportType,
  handleExportChange,
  fetchReportData,
}) => {
  const { t } = useTranslation();
  const exportTypeOptions = [ 
    {value: '0', label: 'NO EXPORT'},
    {value: '1', label: 'PDF'},
    {value: '2', label: 'CSV'}];

  return (
    <>
     <div className="md:hidden mb-8">
        <div className="w-full flex gap-2 mb-4">
            <span className={`${!fileUrl ? "w-full " : "w-1/2"}`}>
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>
            {fileUrl &&  <span className="w-1/2 md:w-1/5">
              <button className="w-full border rounded-lg px-4 py-1.5 md:py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>
            </span>}
           
        </div>
            <span className="md:w-1/5">
              <button className="w-full rounded-lg px-4 py-1.5 md:py-2 bg-custom-bgBlue text-white" onClick={fetchReportData}>{t("periodReport.generate")}</button>
            </span>
      </div>
      <div className="hidden md:flex justify-between mb-12">
            <span className="w-1/7">
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>

            <div className="grid grid-cols-2 grid-flow-row-dense w-2/5 gap-2">
              { <span className={`w-full ${fileUrl ? "" : "invisible"}`}>
                {fileUrl &&  <button className="w-full border rounded-lg px-4 py-1.5 md:py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>}
             
            </span>}
            <span className="w-full ">
              <button className=" w-full rounded-lg px-4 py-1.5 md:py-2 bg-custom-bgBlue text-white" onClick={fetchReportData}>{t("periodReport.generate")}</button>
            </span>
  
            </div>
      </div> 
    </>
  );
};

export default ExportFilterActions;
