import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomTimePickerProps {
  isToTime?: boolean;
  selectedTime?: string;
  onTimeChange?: (time: string) => void;
}

const CustomTimeDropdown: React.FC<CustomTimePickerProps> = ({ isToTime, selectedTime, onTimeChange }) => {
  const timeOptions = [
    '00:00', '01:00', '02:00', '03:00',
    '04:00', '05:00', '06:00', '07:00',
    '08:00', '09:00', '10:00', '11:00',
    '12:00', '13:00', '14:00', '15:00',
    '16:00', '17:00', '18:00', '19:00',
    '20:00', '21:00', '22:00', '23:00'
  ];

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  // Handle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle time selection
  const handleTimeSelect = (time: string) => {
    if (onTimeChange) onTimeChange(time);
    setIsOpen(false); // Close dropdown after selection
  };

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  // Add and remove event listener for clicks outside of the dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          className={`flex justify-between items-center text-xxs md:text-xs w-full px-2 bg-custom-bgBlue  rounded-md h-8 md:h-10 ${isOpen ? "bg-white text-black outline outline-custom-bgBlue" : "text-white"}`}
          onClick={toggleDropdown}
        >
          {selectedTime ? selectedTime : 'Select a time'}
          {isOpen ? <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.5L5 1.5L9 5.5" stroke="#0DB5D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> :  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1.5L5 5.5L9 1.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
}
        </button>
      </div>

      {isOpen && (
        <div className={`absolute z-10 min-w-[207%] mt-2 bg-white border border-custom-bgBlue rounded-md shadow-lg max-h-60 overflow-y-auto ${isToTime ? "right-0 " : ""}`}>
          <p className='pl-2 pt-1'>{t("datePicker.time")}</p>
          <ul className="py-1 text-sm text-gray-700">
            {timeOptions.map((time) => (
              <li
                key={time}
                className={`py-2 cursor-pointer hover:bg-gray-100 ${
                  selectedTime === time ? 'border px-2 border-custom-bgBlue mx-2 rounded-lg' : 'px-4'
                }`}
                onClick={() => handleTimeSelect(time)}
              >
                {time}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomTimeDropdown;
